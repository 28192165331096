import { Controller } from "@hotwired/stimulus"

const COMPANY_PRESENTATION_STAGE_ID = '4910';
const INTERVIEW_QUESTION_STAGE_IDS = ['4920', '4930', '4940', '4950', '4960', '4970', '4990', '5000'];

export default class extends Controller {
  static targets = ["selectionStage", "seminarSchedule", "startAt", "free"]

  connect() {
    this.checkAllStagesAndSchedules();
    this.element.addEventListener('cocoon:after-insert', this.checkAllStagesAndSchedules.bind(this));
  }

  checkAllStagesAndSchedules() {
    this.selectionStageTargets.forEach((el) => {
      this.checkStage(el);
    });
    this.seminarScheduleTargets.forEach((el) => {
      this.checkSchedule(el);
    });
  }

  checkStage(event) {
    const target = event.currentTarget || event;
    const stage = target.value;
    const seminarSchedule = target.closest('.nested-fields').querySelector('.seminar-schedule');
    const free = target.closest('.nested-fields').querySelector('.free');
    if (stage === COMPANY_PRESENTATION_STAGE_ID) {
      seminarSchedule.style.display = 'block';
      free.style.display = 'none';
    } else {
      seminarSchedule.style.display = 'none';
      free.style.display = 'block';
    }

    const interviewQuestion = target.closest('.nested-fields').querySelector('.interview-question');
    if (INTERVIEW_QUESTION_STAGE_IDS.includes(stage)) {
      interviewQuestion.style.display = "block";
    } else {
      interviewQuestion.style.display = "none";
    }
  }

  checkSchedule(event) {
    const target = event.currentTarget || event;
    const selectedValue = target.value;
    const freeInputIds = JSON.parse(target.dataset.freeInputIds);
    const free = target.closest('.start-at').querySelector('.free');
    const stageElement = target.closest('.nested-fields').querySelector('.selection-stage');
    const stage = stageElement.value;
    const selectedOption = target.options[target.selectedIndex];
    const selectedSeminarId = selectedOption.dataset.seminarId;

    // Remove existing link if any
    const existingLink = target.parentNode.querySelector('a.seminar-detail-link');
    if (existingLink) existingLink.remove();

    // Create new link if a seminar is selected
    if (selectedSeminarId) {
      const newLink = document.createElement('a');
      newLink.href = '/seminars/' + selectedSeminarId;
      newLink.target = '_blank';
      newLink.textContent = '説明会詳細';
      newLink.classList.add('float-right');
      newLink.classList.add('seminar-detail-link');
      target.parentNode.insertBefore(newLink, target.parentNode.firstChild.nextSibling);
    }

    if (stage !== COMPANY_PRESENTATION_STAGE_ID) {
      free.style.display = 'block';
    } else {
      if (freeInputIds.includes(parseInt(selectedValue))) {
        free.style.display = 'block';
      } else {
        free.style.display = 'none';
      }
    }
  }
}
