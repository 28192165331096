import mixpanel from "mixpanel-browser";

const productionHost = 'www.innoth-sharing.com';          // 本番ドメイン
const stagingHost = 'zouk-staging.herokuapp.com';     // ステージングドメイン
const prodToken = '74e0b82020d92849072ffe2989802b5a'; // 本番トークン
const stgToken = 'e8193b912887e0f4abd2ad6e4a4ebba9';  // ステージングトークン
const devToken = 'd0d0d50ff1cd7446f01d7395aab0c124';  // 開発トークン

document.addEventListener("turbo:load", function() {
  if (initMixpanel()) {
    setupLinkTracking();
  }
});

// 初期化、ユーザー情報を取得してMixpanelに送信, Adminの場合は初期化されずfalseを返す
function initMixpanel() {
  const userInfo = document.getElementById('user-info');
  if (userInfo) {
    const user_id = userInfo.dataset.userId;
    const user_name = userInfo.dataset.userName;
    const user_email = userInfo.dataset.userEmail;
    if (user_id && user_name && user_email) {
      mixpanel.init(mixpanelToken(), {debug: mixpanelDebug(), track_pageview: 'full-url', persistence: 'localStorage'});
      mixpanel.identify(user_id);
      mixpanel.people.set({
        $name: user_name,
        $email: user_email
      });
      return true;
    }
  }
  return false;
}

// トークンの設定、本番、ステージング、開発用にトークンを返す
function mixpanelToken() {
  if (window.location.hostname.toLowerCase().search(productionHost) >= 0) {
    return prodToken;
  }
  if (window.location.hostname.toLowerCase().search(stagingHost) >= 0) {
    return stgToken;
  }
  return devToken;
}

// デバッグモードの設定、本番のみ false
function mixpanelDebug() {
  if (window.location.hostname.toLowerCase().search(productionHost) >= 0) {
    return false;
  }
  if (window.location.hostname.toLowerCase().search(stagingHost) >= 0) {
    return true;
  }
  return true;
}

function setupLinkTracking() {
  // Mixpanelが初期化されていない場合は何もしない, Adminの場合は初期化されていない
  if (!mixpanel.__loaded) {
    // console.warn('Mixpanel is not initialized.');
    return;
  }

  const links = document.querySelectorAll('a[data-track-click="true"]');
  links.forEach((link) => {
    link.addEventListener('click', function() {
      mixpanel.track('Link Clicked', {
        'Link Text': link.textContent,
        'Link URL': link.href,
        'Source': link.getAttribute('data-source')
      });
    });
  });
}
